import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440,
};

export const layoutConstant = {
  grocerySidenavWidth: "280px",
  containerWidth: "1200px",
  mobileNavHeight: "64px",
  headerHeight: "80px",
  mobileHeaderHeight: "64px",
};

export const BASE_PATH = publicRuntimeConfig.API_URL;
export const TOKEN = publicRuntimeConfig.TOKEN;
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { AppProvider } from "../contexts/app/AppContext";
import { GlobalStyles } from "../utils/globalStyles";
import { theme } from "../utils/theme";

//Binding events.
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

NProgress.configure({ showSpinner: false });

const App = ({ Component, pageProps }: any) => {
  let Layout = Component.layout || Fragment;

  const { setting } = pageProps;

  const domain = setting?.filter(
    (prop) => prop.attributes.Name === "site.domain"
  )[0];

  const title = setting?.filter(
    (prop) => prop.attributes.Name === "site.title"
  )[0];

  const description = setting?.filter(
    (prop) => prop.attributes.Name === "site.description"
  )[0];

  const image = setting?.filter(
    (prop) => prop.attributes.Name === "site.image"
  )[0];

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta property="og:url" content={domain?.attributes.Value} />
        <meta property="og:site_name" content={title?.attributes.Value} />
        <meta property="og:updated_time" content={new Date().toISOString()} />
        {/* thumbnail And title for social media */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={title?.attributes.Value}
          key="title"
        />
        <meta
          property="og:description"
          content={description?.attributes.Value}
          key="description"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:title"
          content={title?.attributes.Value}
          key="twitter-title"
        />
        <meta
          property="twitter:description"
          content={description?.attributes.Value}
          key="twitter-description"
        />
        <meta property="twitter:label1" content="Written by" />
        <meta property="twitter:data1" content="Erick Machado" />
        <meta property="twitter:label2" content="Time to read" />
        <meta
          property="twitter:data2"
          content="10 minutes"
          key="twitter-data2"
        />
        <meta
          property="og:image"
          content={image?.attributes.Media?.data.attributes.url}
          key="image"
        />
        <meta
          property="og:image:secure_url"
          content={image?.attributes.Media?.data.attributes.url}
          key="image-secure"
        />
        <meta
          property="og:image:width"
          content={image?.attributes.Media?.data.attributes.width}
          key="image-width"
        />
        <meta
          property="og:image:height"
          content={image?.attributes.Media?.data.attributes.height}
          key="image-height"
        />
        <meta
          property="og:image:alt"
          content={title?.attributes.Value}
          key="image-alt"
        />
        <meta
          name="apple-mobile-web-app-title"
          content={title?.attributes.Value}
        />
        <meta name="application-name" content={title?.attributes.Value} />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        {/* Google analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LQKRCYR6RJ"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LQKRCYR6RJ');
          `,
          }}
        ></script>
      </Head>
      <GlobalStyles />
      <AppProvider>
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
      </AppProvider>
    </ThemeProvider>
  );
};

export default App;
